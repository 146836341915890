import React, { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../context/authenticationContext/AuthenticationContext";
import { MyInformationsContext } from "../../context/myInformationsContext/MyInformationsContext";
import { getMyInformations } from "../../context/myInformationsContext/apiCalls";
import { Row, Col, Navbar, Dropdown, Nav } from "react-bootstrap";
import Card from "../card/Card";
import CustomToggle from "../customToggle/CustomToggle";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../../context/authenticationContext/AuthenticationAction";
import TheMostRecentMovies from "./TheMostRecentMovies";
import logo from "../../assets/images/logo.png";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import userAvatar from "../../assets/images/user/user.png";

export default function Header() {
  const { dispatch } = useContext(AuthenticationContext);
  const { user, dispatchUser } = useContext(MyInformationsContext);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    getMyInformations(dispatchUser);
  }, [dispatchUser]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const getLinkStyle = (path) => {
    return location.pathname === path ? { color: "#FFBD59" } : { color: "white" };
  };

  return (
    <>
      <header id="main-header">
      {isMobile && <br />}
        <img width={90} src={logo} alt="Netfaraj" className="logo-mobile" />
        <Row className="px-3 m-0">
          <Col>
            {/* Navbar for PC View */}
            <Navbar expand="lg" className="p-0 d-none d-lg-flex">
              <Navbar.Brand className="navbar-brand" href="/" style={{ marginRight: "2px" }}>
                <img className="img-fluid" src={logo} alt="Netfaraj" />
              </Navbar.Brand>
              <Nav className="mx-auto font-weight-bold">
      <Nav.Item>
        <Link to="/" className="nav-link pr-4" style={getLinkStyle("/")}>
          <i className="ri-home-4-line"></i> Home
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/movies" className="nav-link pr-4" style={getLinkStyle("/movies")}>
          <i className="ri-film-line"></i> Movies
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/team" className="nav-link pr-4" style={getLinkStyle("/team")}>
          <i className="ri-tv-line"></i> Series
        </Link>
      </Nav.Item>
    </Nav>
              <div className="navbar-right menu-right d-flex align-items-center">
                <Dropdown className="nav-item">
                  <Dropdown.Toggle as={CustomToggle} variant="search-toggle position-relative">
                    <i className="ri-notification-3-line" style={{ color: "white" }}></i>
                    <span className="bg-danger dots"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="iq-sub-dropdown" align="right">
                    <Card className="shadow-none m-0">
                      <Card.Body>
                        <TheMostRecentMovies />
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="nav-item nav-icon">
                  <Dropdown.Toggle as={CustomToggle} variant="search-toggle">
                    <div className="iq-user-dropdown search-toggle p-0 d-flex align-items-center active">
                    <i className="ri-user-line"></i>

                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="iq-sub-dropdown iq-user-dropdown" align="right">
                    <Card className="shadow-none m-0">
                      <Card.Body className="p-0 pl-3 pr-3">
                      <br />
                        {/* Profile Links 
                        <Link to="/user-profile" className="iq-sub-card setting-dropdown">
                          <div className="media align-items-center">
                            <div className="right-icon">
                              <i className="ri-file-user-line text-primary"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="my-0">View</h6>
                            </div>
                          </div>
                        </Link>*/}
                        {/* More profile-related links */}
                        <Link to="/user-account-settings" className="iq-sub-card setting-dropdown">
                          <div className="media align-items-center">
                            <div className="right-icon">
                              <i className="ri-settings-4-line text-primary"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="my-0">Profile settings</h6>
                            </div>
                          </div>
                        </Link>
                        <Link to="/my-seen-movies" className="iq-sub-card setting-dropdown">
                          <div className="media align-items-center">
                            <div className="right-icon">
                              <i className="ri-movie-line text-primary"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="my-0">Watched movies</h6>
                            </div>
                          </div>
                        </Link>
                        <Link to="/login" className="iq-sub-card setting-dropdown">
                          <div className="media align-items-center">
                            <div className="right-icon">
                              <i className="ri-logout-circle-line text-primary"></i>
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="my-0">
                                <span onClick={() => dispatch(logout())}>Logout</span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Navbar>
          </Col>
        </Row>
      </header>

      {/* Bottom Navbar for Mobile View */}
      <Navbar fixed="bottom" className="d-lg-none bottom-navbar glass-effect">
      <Nav className="mx-auto font-weight-bold">
        <Nav.Item>
          <Link to="/" className="nav-link pr-4" style={getLinkStyle("/")}>
            <i className="ri-home-4-line"></i> Home
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/movies" className="nav-link pr-4" style={getLinkStyle("/movies")}>
            <i className="ri-film-line"></i> Movies
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/team" className="nav-link pr-4" style={getLinkStyle("/team")}>
            <i className="ri-tv-line"></i> Series
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Dropdown className="nav-item nav-icon">
            <Dropdown.Toggle as="a" className="nav-link pr-4">
              <div className="flex flex-col items-center">
                <i className="ri-user-line text-6xl font-bold text-white"></i> {/* Add text-white class */}
                <br />
                <span className="text-2xl pt-2 font-bold text-white"> {/* Add text-white class */}
                  user
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="iq-sub-dropdown iq-user-dropdown centered-dropdown"
              align="right"
            >
              <br />
              <Card className="shadow-none m-0">
                <Card.Body className="p-0 pl-3 pr-3">
                  <Link to="/user-account-settings" className="iq-sub-card setting-dropdown">
                    <div className="media align-items-center">
                      <div className="right-icon">
                        <i className="ri-settings-4-line text-primary"></i>
                      </div>
                      <div className="media-body ml-3">
                        <h6 className="my-0">Profile settings</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/my-seen-movies" className="iq-sub-card setting-dropdown">
                    <div className="media align-items-center">
                      <div className="right-icon">
                        <i className="ri-movie-line text-primary"></i>
                      </div>
                      <div className="media-body ml-3">
                        <h6 className="my-0">Watched movies</h6>
                      </div>
                    </div>
                  </Link>
                  <Link to="/login" className="iq-sub-card setting-dropdown">
                    <div className="media align-items-center">
                      <div className="right-icon">
                        <i className="ri-logout-circle-line text-primary"></i>
                      </div>
                      <div className="media-body ml-3">
                        <h6 className="my-0">
                          <span onClick={() => dispatch(logout())}>Logout</span>
                        </h6>
                      </div>
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Dropdown.Menu>
          </Dropdown>
        </Nav.Item>
      </Nav>
    </Navbar>

    </>
  );
}
