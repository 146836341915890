import React from 'react';

const Circle = () => {
  return (
    <div style={{
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      backgroundColor: 'yellow',
      margin: '10px',
    }}>
    </div>
  );
};

export default Circle;