import React, { useState, useEffect } from "react";
import BannerPages from "../../components/banners/BannerPages";
//import MembersTeam from "../../components/team/MembersTeam";
import TextPromo from "../../components/textPromo/TextPromo";
import axios from "axios";
import Header from "../../components/header/Header";
//import Banner from "../../components/banners/BannerHomePage";
import ListMovies from "../../components/movies/ListMovies";
import ParallaxSeries from "../../components/parallax/ParallaxSeries";
//import ParallaxHomePage from "../../components/parallax/ParallaxHomePage";
//import AnnouncedMovies from "../../components/announcedMovies/AnnouncedMovies";

const url = "https://application.netfaraj-dz.com/api";

export default function Team() {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const getRandomLists = async () => {
      try {
        const res = await axios.get(`${url}/categories-movies/`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        });
        setLists(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRandomLists();
  }, []);

  return (
    <>
      <Header />
      <br />
      <br />
      <br />
      <ParallaxSeries />
    </>
  );
}
