// frontenduser/src/components/watchlist/WatchlistCard.js
import React from "react";
import { Card, Button } from "react-bootstrap";
import placeholderMovie from "../../assets/images/movie-thumb/placeholderMovie.jpg";

export default function WatchlistCard({ movie, removeFromWatchlist }) {
  return (
    <Card style={{ width: "18rem", margin: "1rem" }}>
      <Card.Img variant="top" src={movie.image || placeholderMovie} />
      <Card.Body>
        <Card.Title>{movie.title}</Card.Title>
        <Card.Text>
          Year: {movie.year || "Unknown"} <br />
          Duration: {movie.duration || "Unknown"}
        </Card.Text>
        <Button variant="danger" onClick={() => removeFromWatchlist(movie)}>
          Remove
        </Button>
      </Card.Body>
    </Card>
  );
}