import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box, Typography, Card, CardMedia, CardContent,
  Dialog, DialogContent, Button, IconButton
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Circle from './Circle';

const url = "https://application.netfaraj-dz.com/api";

export default function EnhancedCarousel() {
  const [categories, setCategories] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const scrollRef = useRef(null);

  useEffect(() => {
    const getCategoriesAndMovies = async () => {
      try {
        const categoryRes = await axios.get(`${url}/categories-movies/`, {
          headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        });

        const movieRes = await axios.get(`${url}/movie/`, {
          headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        });

        const allMovies = movieRes.data;
        const categoriesWithMovies = categoryRes.data.map((category) => ({
          ...category,
          movies: category.content.map((movieId) => allMovies.find((movie) => movie._id === movieId)).filter(Boolean),
        }));

        setCategories(categoriesWithMovies);
      } catch (error) {
        console.log(error);
      }
    };
    getCategoriesAndMovies();
  }, []);

  const handleCardClick = (category) => {
    setSelectedMovie(category);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setSelectedMovie(null);
  };

  const handleScroll = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };



  const handleWatchNow = (trailer) => {
    window.open(trailer, '_blank'); // Opens the trailer in a new tab
  };

  return (
    <Box sx={{ bgcolor: 'black', color: 'white', p: 3, overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Circle />
        <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>Trending Series</Typography>
      </Box>

      {/* Search Input */}

      {categories.length === 0 ? (
        <Typography variant="h6">No results found...</Typography>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={() => handleScroll('left')}
            sx={{
              position: 'absolute',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
            }}
          >
            <ArrowBackIos sx={{ color: 'white' }} />
          </IconButton>
          <Box
            ref={scrollRef}
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflowX: 'auto',
              gap: 2,
              py: 2,
              scrollbarWidth: 'none', // Hide scrollbar for Firefox
              '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari, and Opera
            }}
          >
            {categories.map((category) => (
              <Card
                key={category._id}
                onClick={() => handleCardClick(category)}
                sx={{
                  minWidth: isMobile ? '220px' : '271px',
                  height: isMobile ? '150px' : '355px',
                  bgcolor: 'transparent',
                  transition: 'all 0.3s ease',
                  border: '2px solid #333',
                  borderRadius: '8px',
                  position: 'relative',
                  cursor: 'pointer',
                  mx: 1, // Add horizontal margin for spacing
                  my: 1, // Add vertical margin for spacing
                  '&:hover': {
                    boxShadow: '0 0 0 2px yellow',
                    transform: 'scale(1.05)',
                    zIndex: 1,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={category.movies[0].image}
                  alt={category.movies[0].title}
                  height={isMobile ? '200' : '120'}
                />
                <CardContent
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: 'rgba(0, 0, 0, 0.7)',
                    color: 'white',
                    p: 1,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 'bold',
                      color: 'white',
                      fontSize: isMobile ? '0.75rem' : '1rem', // Adjust font size based on card size
                      whiteSpace: 'nowrap', // Ensure the text stays on one line
                      overflow: 'hidden', // Hide overflow text
                      textOverflow: 'ellipsis', // Add ellipsis for overflow text
                    }}
                    noWrap
                  >
                    {category.title}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
          <IconButton
            onClick={() => handleScroll('right')}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
              zIndex: 2,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.7)' },
            }}
          >
            <ArrowForwardIos sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      )}

      {selectedMovie && (
        <Dialog fullScreen open={openPopup} onClose={handleClosePopup} maxWidth="md">
          <DialogContent sx={{ bgcolor: 'black', color: 'white' }}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>{selectedMovie.title}</Typography>
            <video controls style={{ width: '100%' }}>
              <source src={selectedMovie.movies[0].trailer} type="video/mp4" />
            </video>
            <Box sx={{ mt: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Circle />
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Episodes</Typography>
              </Box>
              <Box sx={{ display: 'flex', overflowX: 'auto', gap: 2 }}>
                {selectedMovie.movies.slice(1).map((movie) => (
                  <Card key={movie._id} sx={{ minWidth: 200 }}>
                    <CardMedia component="img" image={movie.image} alt={movie.title} sx={{ height: 150 }} />
                    <Typography variant="body2" textAlign="center"></Typography>
                    <Button 
                      variant="contained" 
                      style={{ backgroundColor: '#FFBD59', color: 'white' }} 
                      fullWidth 
                      onClick={() => handleWatchNow(movie.trailer)} // Add the onClick event
                    >
                      {movie.title}
                    </Button>
                  </Card>
                ))}
              </Box>
            </Box>
            <Button onClick={handleClosePopup} 
            style={{ color: '#FFBD59' }}
            sx={{ mt: 3 }}>Return</Button>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}