import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import MoviesPage from "../../components/parallax/MoviesPage";
import BannerPages from "../../components/banners/BannerPages";
import { Parallax } from "swiper";
import ParallaxMovies  from "../../components/parallax/ParallaxMovies";

const url = "https://application.netfaraj-dz.com/api";

export default function Movies() {
  const [lists, setLists] = useState([]);
  const [genre, setGenre] = useState("");

  useEffect(() => {
    const getRandomLists = async () => {
      try {
        const res = await axios.get(
          `${url}/categories-movies${genre ? "?genre=" + genre : ""}`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );
        setLists(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRandomLists();
  }, [genre]);

  return (
    <>
      <Header />
      <br />
      <br />
      <br />

      <ParallaxMovies />
 

    </>
  );
}
