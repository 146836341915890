// frontenduser/src/pages/blog/Blog.js
import React from "react";
import { Box, Typography } from "@mui/material";
import { useWatchlistStore } from "../../store";
import WatchlistCard from "./WatchlistCard";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../../components/header/Header";

export default function Watchlist() {
  const { watchlist, removeFromWatchlist } = useWatchlistStore();

  return (
    <>

    <Header />

    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>

      </Typography>
      {watchlist.length > 0 ? (
        <Container>
          <Row>
            {watchlist.map((movie, index) => (
              <Col key={index} sm="12" md="6" lg="4">
                <WatchlistCard movie={movie} removeFromWatchlist={removeFromWatchlist} />
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        <Typography>No movies in watchlist.</Typography>
      )}
    </Box>

    </>
  );
}