import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import notifyError from "../../components/notify/notifyError";
import axios from "axios";
import notifySuccess from "../../components/notify/notifySuccess";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

const url = "https://application.netfaraj-dz.com/api";

export default function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const history = useHistory();

  const handleFinish = async (e) => {
    e.preventDefault();
    if (firstName.length < 3) {
      notifyError("First name must contain at least 3 characters.");
    } else if (lastName.length < 3) {
      notifyError("Last name must contain at least 3 characters.");
    } else if (email.length < 5) {
      notifyError("Email address must contain at least 5 characters.");
    } else if (username.length < 5) {
      notifyError("Username must contain at least 5 characters.");
    } else if (phoneNumber.length !== 10) {
      notifyError("Phone number must contain exactly 10 digits.");
    } else if (password.length < 8) {
      notifyError("Password must contain at least 8 characters.");
    } else {
      axios.post(`${url}/user/register/`, {
        firstName,
        lastName,
        email,
        username,
        phoneNumber,
        password,
      })
        .then((res) => {
          if (res.status === 201) {
            notifySuccess(
              "Your account has been successfully created! Please log in to the application."
            );
            setTimeout(() => {
              history.push("/login");
            }, 6000);
          }
        })
        .catch(() =>
          notifyError(
            "Your account could not be created. The email address, username, or phone number already belongs to another user."
          )
        );
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <Container>
          <Row className="justify-content-center align-items-center height-self-center">
            <Col lg="7" md="12" className="align-self-center">
              <div className="sign-user_card ">
                {/* Input form */}
                <div className="sign-in-page-data">
                  <div className="sign-in-from w-100 m-auto">
                    <h4 className="mb-3 text-center">Register</h4>
                    <Form>
                      <Row>
                        {/* Input first name */}
                        <Col md="6">
                          <Form.Group>
                          <h6 className="text-[#fff] mb-2">

                            First Name
                            </h6>

                            <input
                              type="text"
                              className="form-control mb-0"
                              id="firstName"
                              placeholder="Enter first name"
                              autoComplete="off"
                              onChange={(e) => {
                                setFirstName(e.target.value);
                              }}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* Input last name */}
                        <Col md="6">
                          <Form.Group>
                          <h6 className="text-[#fff] mb-2">
                              
                            Last Name
                            </h6>

                            <input
                              type="email"
                              className="form-control mb-0"
                              id="lastName"
                              placeholder="Enter last name"
                              autoComplete="off"
                              onChange={(e) => {
                                setLastName(e.target.value);
                              }}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* Input email address */}
                        <Col md="6">
                          <Form.Group>
                          <h6 className="text-[#fff] mb-2">
                              
                            Email Address
                            </h6>

                            <input
                              type="email"
                              className="form-control mb-0"
                              id="emailAddress"
                              placeholder="Enter email address"
                              autoComplete="off"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* Input username */}
                        <Col md="6">
                          <Form.Group>
                          <h6 className="text-[#fff] mb-2">
                          Username
                            </h6>
                            <input
                              type="text"
                              className="form-control mb-0"
                              id="username"
                              placeholder="Username"
                              autoComplete="off"
                              onChange={(e) => {
                                setUsername(e.target.value);
                              }}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* Input Password */}
                        <Col md="6">
                          <Form.Group>
                            <h6 className="text-[#fff] mb-2">
                            Phone Number
                            </h6>


                            <input
                              type="text"
                              className="form-control mb-0 text-gray"
                              id="phoneNumber"
                              placeholder="Enter phone number"
                              autoComplete="on"
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* Input repeat password */}
                        <Col md="6">
                          <Form.Group>
                            <h6 className="text-[#fff] mb-2">
                            Password
                            </h6>
                            <input
                              type="password"
                              className="form-control mb-0"
                              id="password"
                              placeholder="Enter password"
                              autoComplete="on"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                    {/* Register button */}
                    <Link to="/">
                      <div className="sign-info">
                        <Button
                          className="bg-[#FFBD59] text-white text-md font-weight-norma"
                          style={{ backgroundColor: "#FFBD59" }}
                          onClick={handleFinish}
                        >
                          Register
                        </Button>
                      </div>
                    </Link>
                  </div>
                </div>
                {/* Text */}
                <div className="mt-3">
                  <div className="d-flex justify-content-center links">
                  <p style={{ fontSize: "0.8rem" }}>Already have an account?</p>
                  <Link
                    to="/login"
                    className="text-primary ml-2"
                    style={{ fontWeight: "bold", fontSize: "1rem" }}
                  >
                    Log In
                  </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ToastContainer />
    </>
  );
}
