import React, { useState, useContext, useEffect } from "react";
import { MyInformationsContext } from "../../context/myInformationsContext/MyInformationsContext";
import { getMyInformations } from "../../context/myInformationsContext/apiCalls";
import axios from "axios";
import { Link } from "react-router-dom";
import SwiperCore, { EffectFade, Navigation, Thumbs, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import placeholderMovie from "../../assets/images/movie-thumb/placeholderMovie.jpg";

SwiperCore.use([EffectFade, Navigation, Thumbs, Pagination]);

const url = "https://application.netfaraj-dz.com/api";

export default function MovieCard({ item }) {
  const [movie, setMovie] = useState({});
  const { user, dispatchUser } = useContext(MyInformationsContext);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getMyInformations(dispatchUser);
    const getMovie = async () => {
      try {
        const res = await axios.get(`${url}/movie/find/` + item, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        });
        setMovie(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getMovie();
  }, [item, dispatchUser]);

  const handleCardClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="block-images position-relative" onClick={handleCardClick}>
      {/* Image thumbnail */}
      <div className="img-box">
        <img
          src={movie.image || placeholderMovie}
          className="img-fluid"
          alt={movie.title || "Movie Thumbnail"}
        />
      </div>

      {/* Full-Screen Modal */}
      {isOpen && (
        <div className="fullscreen-modal" onClick={handleClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
            <h1>{movie.title}</h1>
            <img src={movie.image || placeholderMovie} alt={movie.title} />
            <p>{movie.description}</p> {/* Ensure movie.description is fetched from API */}
            <div className="modal-footer">
              <Link
                to={{ pathname: "/movie-details/" + movie.title, movie: movie }}
                className="btn btn-hover iq-button"
              >
                Watch
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
