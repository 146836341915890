import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/header/Header";
import Banner from "../../components/banners/BannerHomePage";
import ListMovies from "../../components/movies/ListMovies";
import ParallaxHomePage from "../../components/parallax/ParallaxHomePage";
import ParallaxHomePage2 from "../../components/parallax/ParallaxHomePage2";
//import AnnouncedMovies from "../../components/announcedMovies/AnnouncedMovies";
import Footer from "../../components/footer/Footer";
import ParallaxHomePage3 from "../../components/parallax/ParallaxHomePage3";

const url = "https://application.netfaraj-dz.com/api";

export default function Home() {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const getRandomLists = async () => {
      try {
        const res = await axios.get(`${url}/categories-movies/`, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        });
        setLists(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRandomLists();
  }, []);

  return (
    <>

      <Header /> 

      <Banner />     
      
      <ParallaxHomePage />

      <ParallaxHomePage2 />

      <ParallaxHomePage3 />

    <br/>
    <br/>
    <br/>
    <br/>
    </>
  );
}
