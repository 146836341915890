import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, Button, Chip, Link, IconButton, Snackbar } from "@mui/material";
import { PlayArrow, ArrowBackIos, ArrowForwardIos, CheckCircle } from "@mui/icons-material";
import { useWatchlistStore } from "../../store";

const url = "https://application.netfaraj-dz.com/api";

export default function Banner() {
  const [content, setContent] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const { addToWatchlist } = useWatchlistStore();

  useEffect(() => {
    const getSliderMovie = async () => {
      try {
        const res = await axios.get(`${url}/movie/randomMovie/`, {
          headers: {
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        });
        setContent(res.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    getSliderMovie();
  }, []);

  const imageSlider = content.image || "/placeholder.svg?height=600&width=1200"; // Fallback image

  const handleAddToWatchlist = (movie) => {
    addToWatchlist(movie);
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: { xs: "400px", md: "600px" },
        overflow: "hidden",
        color: "white",
      }}
    >
      <Snackbar
        open={showNotification}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setShowNotification(false)}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            fontSize: "1rem",
          },
        }}
        message={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CheckCircle sx={{ color: "green" }} />
            Added to Watchlist!
          </Box>
        }
      />

      <Box
        component="img"
        src={imageSlider}
        alt={content.title || "Movie"}
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.5)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to top, rgba(0,0,0,0.8) 0%, transparent 100%)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: "15%", md: "10%" },
          left: "10%",
          right: "10%",
          p: { xs: 2, md: 4 },
        }}
      >
        {/* Movie Title for PC */}
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: "bold", 
            fontSize: { xs: "0px", md: "3rem" },
             display: { xs: "none", md: "block" } }}
        >
          {content.title}
        </Typography>

        {/* Movie Details for PC */}
        <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 2, mb: 2 }}>
          <Chip
            label={content.limitAge || "PG-13"}
            variant="outlined"
            size="small"
            sx={{
              color: "white",
              borderColor: "white",
              borderRadius: 0,
              fontSize: "0.875rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          />
          <Typography variant="body2">{content.year || "2021"}</Typography>
          <Typography variant="body2">{content.duration || "1 hr 43 min"}</Typography>
          <Link href="#" color="inherit" underline="always" variant="body2">
            Leave a review
          </Link>
          <Typography variant="body2">{content.genre || "Drama, History"}</Typography>
        </Box>

        {/* Movie Description for PC */}
        <Typography
          variant="body2"
          sx={{
            maxWidth: "600px",
            color: "grey.300",
            fontSize: "1rem",
            mb: 2,
            display: { xs: "none", md: "block" }, // Only show on larger screens
          }}
        >
          {content.description || "Movie description goes here."}
        </Typography>

        {/* Button Section for PC */}
        <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "space-between", alignItems: "center" }}>
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              height: 48,
              px: 3,
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            Trailer
          </Button>

          <Button
            variant="contained"
            startIcon={<PlayArrow />}
            sx={{
              bgcolor: "#FFBD59",
              color: "white",
              height: 48,
              px: 3,
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              "&:hover": {
                bgcolor: "#FFBD59", // Maintain the same background color
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Maintain the same box shadow
              },
            }}
          >
            Play
          </Button>

          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleAddToWatchlist(content)}
            sx={{
              height: 48,
              px: 3,
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            +
          </Button>
        </Box>

        {/* Mobile Layout */}
        <Box sx={{ display: { xs: "flex", md: "none" }, justifyContent: "space-between", alignItems: "center", mt: 2 }}>
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              position: "absolute",
              left: "10px",
              bottom: "20px",
              height: 48,
              px: 2,
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            Tr
          </Button>

          <Button
            variant="contained"
            startIcon={<PlayArrow />}
            sx={{
              bgcolor: "warning.main",
              color: "white",
              height: 48,
              px: 3,
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              position: "relative",
              top: "-50px", // Move the button higher
              left: "30%",  // Center the button
              zIndex: 2,
            }}
          >
            Play
          </Button>

          <Button
            variant="outlined"
            color="inherit"
            onClick={() => handleAddToWatchlist(content)}
            sx={{
              position: "absolute",
              right: "10px",
              bottom: "20px",
              height: 48,
              px: 2,
              fontSize: "1rem",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            +
          </Button>
        </Box>
      </Box>

      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
      >
        <ArrowBackIos />
      </IconButton>
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          color: "white",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
}
